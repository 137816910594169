// @flow
import * as React from 'react'
import styled from '@emotion/styled'

import { Button, Form, Icon, text } from '@toggl/ui'
import { colors, media } from '@toggl/style'
import AppleLogin from 'components/AppleLogin'
import GoogleLogin from 'components/GoogleLogin'

export default function Signup({
  signupBackground,
}: {|
  signupBackground?: string,
|}) {
  const [agreed, setAgreed] = React.useState(false)
  const [error, setError] = React.useState(null)

  return (
    <SignupSectionWrapper>
      <SignupSection background={signupBackground}>
        <SignupHeader>Sign-up for Toggl Track</SignupHeader>

        <SignupCheckbox>
          <Form.Checkbox
            onChange={setAgreed}
            id="term-of-service"
            color={colors.darkPurple}
          >
            <span>I agree to the terms of service and privacy policy.</span>
          </Form.Checkbox>
        </SignupCheckbox>
        <StyledError>
          <text.P3>{error}</text.P3>
        </StyledError>
        <SignupButtons>
          <GoogleLogin
            disabled={!agreed}
            onError={(message) => setError(message)}
          />
          <AppleLogin
            disabled={!agreed}
            onError={(message) => setError(message)}
          />
        </SignupButtons>

        <SignupFooter>
          <Icon.Email />
          <span>or sign-up via email</span>
        </SignupFooter>
      </SignupSection>

      <TrySection>
        <TrySectionHeader>Try Toggl Track’s free plan today!</TrySectionHeader>
        <TrySectionDescription>
          Interested in a paid plan? Upgrade anytime for a 30-day free trial.
        </TrySectionDescription>
        <TrySectionDivider />
        <TrySectionFooter>
          <span>Or want us to show you around?</span>
          <Button.Primary>Request a demo</Button.Primary>
        </TrySectionFooter>
      </TrySection>
    </SignupSectionWrapper>
  )
}

const TrySectionFooter = styled.div`
  ${text.paragraph2};

  button {
    margin-top: 29px;
  }
`

const TrySectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.almostWhite};
  margin: 26px 0;
`

const TrySectionDescription = styled.div`
  ${text.paragraph2};
`

const TrySectionHeader = styled.div`
  ${text.heading3};

  margin-bottom: 38px;
`

const TrySection = styled.div`
  display: none;

  ${media.mq[1]} {
    width: 588px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.darkPurple};
    color: ${({ theme }) => theme.accent};
    padding: 76px 126px 80px 165px;

    transform: translateY(-300px) translateX(600px);
  }
`

const SignupFooter = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;
  gap: 9px;
  align-items: center;

  svg {
    width: 30px;
  }

  ${media.mq[1]} {
    margin-top: 39px;
  }
`

const SignupButtons = styled.div`
  button {
    max-width: 100%;

    &:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  ${media.mq[1]} {
    display: flex;
    flex-direction: row;
    gap: 19px;
    button {
      max-width: inherit;
    }
  }
`

const SignupCheckbox = styled.div`
  margin-bottom: 35px;

  label > span {
    ${text.paragraph3};
    color: ${colors.darkPurple};
  }

  ${media.mq[1]} {
    margin-bottom: 38px;
  }
`

const SignupHeader = styled.div`
  ${text.heading3};
  width: 200px;
  margin: 0 auto;
  margin-bottom: 33px;

  color: ${colors.darkPurple};

  ${media.mq[1]} {
    ${text.heading2};
    color: ${colors.darkPurple};
    width: 330px;
    margin: 0;
    margin-bottom: 40px;
  }
`

const SignupSection = styled.div`
  background: ${({ background, theme }) => background || theme.accent};
  padding: 51px 20px 56px 20px;
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    background: ${({ theme }) => theme.primary};
    width: 710px;
    padding: 60px 100px;
    z-index: 1;
    position: relative;
  }
`

const SignupSectionWrapper: React.ComponentType<any> = styled.div`
  ${media.mq[1]} {
    padding-top: 120px;
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 920px;
  }
`

const StyledError = styled.div``
