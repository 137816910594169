// @flow
import * as React from 'react'

import IndexLayout from '../../layouts'
import LayoutTrack from '../../components/LayoutTrack'
import styled from '@emotion/styled'
import { Hero, text, Section, Accordion } from '@toggl/ui'
import { media, colors } from '@toggl/style'
import { hooks, Icon, Form, Button } from '@toggl/ui'
import { Link } from 'gatsby'
import { useFormik } from 'formik'
import Signup from 'components/Signup'
import ogImage from 'images/uploads/toggl-track-contact-us.jpg'

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'invalid email address'
  }

  if (!values.name) {
    errors.name = 'required'
  }

  if (!values.message) {
    errors.message = 'required'
  }
  return errors
}

const faqData = [
  {
    title: 'Who is behind Toggl?',
    description:
      'Toggl is made by a remote team spread all over the globe. One thing we all share is a belief that software should empower people, and not get in their way. Our headquarters are in Estonia: Toggl OÜ, Tartu mnt 25, Tallinn, 10117, Estonia.',
  },
  {
    title: 'Do you have any tutorials to help me get started?',
    description:
      'Yes, we do! Our knowledge base has detailed guides for all available features across all Toggl Track apps - web, desktop, mobile, and browser plugin. Articles contain images or GIFs (sometimes both) to help you better understand what the content is referring to.',
  },
  {
    title: 'Can I track time on my phone?',
    description:
      'Toggl Track is available as a mobile app on both iOS and Android. Both apps will allow you to track time and view some basic reports.',
  },
  {
    title: 'How can I track time as part of a group?',
    description:
      'You can start by creating a new workspace that will be used for this group of people. With the workspace created, open the “Team” page and start inviting others to join! Each person will receive an email invitation from you to track time together.',
  },
  {
    title: 'Can I cancel my subscription?',
    description:
      'You can cancel at any time by logging into your Toggl Track account, opening the Subscription page from the left navigation bar, and downgrading to the Free plan. Your account will retain access to paid features through the end of your current billing cycle, at which point you will be downgraded to the Free plan.',
  },
]

const HeroComponent = ({ children }) => {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const [submitted, setSubmitted] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validate,
    validateOnChange: false,
    // $FlowFixMe useFormik type definition is outdated
    onSubmit: async (values) => {
      alert(JSON.stringify(values))
      setTimeout(() => setSubmitted(true))
    },
  })

  return (
    <Hero.Wrapper>
      {children}

      {isDesktop && (
        <TitleWrapper>
          <Title>
            <text.H1>Get in touch</text.H1>
          </Title>
        </TitleWrapper>
      )}

      <Section.Block flex>
        <StyledLeft>
          <Title>
            <text.H3>
              We'd love to hear from <em>you!</em>
            </text.H3>
          </Title>
          <text.P1>
            Use this form to send us questions or feedback about Toggl Track.{' '}
          </text.P1>
          <TogglFamily>
            <Brand>
              <Link to="/plan/">
                <Icon.Plan />
              </Link>
              <Link to="/plan/">
                To receive help for the visual calendar tool, visit Toggl Plan.
              </Link>
            </Brand>
            <Brand>
              <a href="mailto:support@hire.toggl.com">
                <Icon.Hire />
              </a>
              <a href="mailto:support@hire.toggl.com">
                For help with the hiring tool, email Toggl Hire.
              </a>
            </Brand>
          </TogglFamily>
          <StyledMarketingContact>
            <Title>
              <text.H3>Connect with our marketing team</text.H3>
            </Title>
            <text.P2>
              Please reach out if you have a media opportunity or business
              proposal:
            </text.P2>
            <StyledContacts>
              <Contact>
                <text.H4>
                  Paid Advertising and Sponsorship opportunities
                </text.H4>
                <text.P2>elise [at] toggl [dot] com</text.P2>
              </Contact>

              <Contact>
                <text.H4>Press & Media Requests / Conferences & Events</text.H4>
                <text.P2>emma [at] toggl [dot] com</text.P2>
              </Contact>

              <Contact>
                <text.H4>B2B and Marketing Collaborations</text.H4>
                <text.P2>caitlyn [at] toggl [dot] com</text.P2>
              </Contact>

              <Contact>
                <text.H4>
                  Write for our{' '}
                  <a
                    href="https://toggl.com/blog"
                    rel="noreferrer"
                    target="_blank"
                  >
                    blog
                  </a>
                </text.H4>
                <text.P2>
                  You can submit a pitch to our content team using{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc4ZYhcSYp_rtJgrumbzXBobGe1bWD4jRft7ADUI1AWeGe_jw/viewform"
                  >
                    this form.
                  </a>{' '}
                  You can find our pitching guidelines{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.notion.so/toggl/Toggl-Blog-Pitch-Guidelines-Public-719da35ad7c54f8abf9eea36f3ab9fd5"
                  >
                    here
                  </a>
                  .
                </text.P2>
              </Contact>
            </StyledContacts>
          </StyledMarketingContact>
        </StyledLeft>

        <StyledRight>
          {!submitted && (
            <>
              <text.H4>
                Ask a question or submit feedback about Toggl Track
              </text.H4>
              <StyledForm
                error={formik.errors.form}
                onSubmit={formik.handleSubmit}
              >
                <Form.Input
                  id="name"
                  label={<label htmlFor="name">Name</label>}
                  name="name"
                  type="text"
                  placeholder={'Name'}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={
                    formik.errors.name ? <div>{formik.errors.name}</div> : null
                  }
                />

                <Form.Input
                  id="email"
                  label={<label htmlFor="email">Email</label>}
                  name="email"
                  type="email"
                  placeholder={'Email'}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={
                    formik.errors.email ? (
                      <div>{formik.errors.email}</div>
                    ) : null
                  }
                />

                <Form.Textarea
                  id={'message'}
                  label={<label htmlFor="message">Message</label>}
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={
                    formik.errors.message ? (
                      <div>{formik.errors.message}</div>
                    ) : null
                  }
                />
                <Button.Primary type="submit" disabled={formik.isSubmitting}>
                  Send Message
                </Button.Primary>
              </StyledForm>
            </>
          )}
          {submitted && (
            <SuccessMessage>
              <text.H3>Message Sent</text.H3>
              <text.P1>
                We're here to help! Our awesome Support Team will get back to
                you shortly.
              </text.P1>
            </SuccessMessage>
          )}
        </StyledRight>
      </Section.Block>
    </Hero.Wrapper>
  )
}

const ContactPage = () => {
  return (
    <IndexLayout
      title={'Toggl Track: Contact Us'}
      description={
        'Toggl Track will help you track your time, manage projects, coordinate with your team, and see your profitability - all backed up by powerful reporting.'
      }
      ogImage={ogImage}
    >
      <LayoutTrack Hero={HeroComponent}>
        <Section.Block background={colors.fadedPurple}>
          <Accordion title="FAQ's" sections={faqData} active={0} />
        </Section.Block>
        <Section.Block>
          <Signup />
        </Section.Block>
      </LayoutTrack>
    </IndexLayout>
  )
}

const SuccessMessage = styled.div``
const TitleWrapper = styled.div`
  background: ${colors.fadedPurple};
  padding-top: 60px;
`
const StyledForm = styled.form`
  button {
    margin: 40px auto;
  }

  ${media.mq[1]} {
    button {
      margin: 40px 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
`

const StyledContacts = styled.div`
  padding: 20px 0;
`
const Contact = styled.div`
  padding: 30px 0;
  h4 {
    color: ${(p) => p.theme.primary};
  }
  p {
    margin-top: 20px;
  }

  a {
    color: ${(p) => p.theme.primary};
  }
`
const StyledMarketingContact = styled.div`
  > p {
    margin: 20px 0;
  }
`

const Brand = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 20px;
  }

  margin-bottom: 30px;
`

const TogglFamily = styled.div`
  padding: 30px 0;

  svg {
    max-width: 115px;
  }

  ${media.mq[1]} {
    padding: 60px 0;
  }
`

const StyledLeft = styled.div`
  p {
    margin-top: 30px;
  }

  ${media.mq[1]} {
    max-width: 50%;
    padding-right: 120px;
  }
`
const StyledRight = styled.div`
  input,
  textarea {
    width: 100%;
  }

  > h4 {
    margin-bottom: 30px;
  }
`

const Title = styled.div`
  h1 {
    color: ${(p) => p.theme.primary};
    text-align: center;
  }

  h3 {
    color: ${(p) => p.theme.primary};
  }
`

export default ContactPage
