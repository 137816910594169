// @flow

import * as React from 'react'
import { Button, Icon } from '@toggl/ui'
import { useAuth } from '@toggl/auth'

const AppleLogin = ({
  onError,
  disabled,
}: {
  onError: (message: string) => void,
  disabled?: boolean,
}) => {
  const [ready, auth] = useAuth()

  const appleLogin = React.useCallback(async () => {
    try {
      const url = await auth.appleSignIn()

      window.location.assign(url)
    } catch (err) {
      onError(
        "Sorry, we couldn't sign you in with Apple. Please choose another method or contact support."
      )
    }
  }, [auth, onError])

  return (
    <Button.SocialLogin
      type="button"
      disabled={!ready || disabled}
      onClick={appleLogin}
    >
      <Icon.Apple />
      Login via Apple
    </Button.SocialLogin>
  )
}

export default AppleLogin
